import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';

export default function ThemeConfig({ children }) {
  const theme = createTheme({
    typography,
    shape,
    palette,
    breakpoints,
  });

  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import ReactDOM from 'react-dom';
import ThemeConfig from 'src/theme';
import { Provider } from 'react-redux';
import createStore from 'src/redux/create';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Fade } from '@mui/material';
import './index.css';
import 'react-day-picker/dist/style.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <ThemeConfig>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionComponent={Fade}
        >
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeConfig>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

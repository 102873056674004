import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import logoImg from 'src/assets/images/pcn-logo.png';
/* eslint-disable react/jsx-props-no-spreading */

const NavbarBrand = ({ ...props }) => {
  return (
    <Stack direction="row" alignItems="center" {...props}>
      <Link to="/">
        <Box>
          <img
            src={logoImg}
            alt="pcn logo"
            height="44px"
            width="244px"
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </Link>
    </Stack>
  );
};

export default NavbarBrand;

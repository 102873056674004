import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import NavbarBrand from './components/NavbarBrand';
import NavbarItem from './components/NavbarItem';
import MobileView from './MobileView';
import ProfileDashboard from './components/ProfileDashboard';
import StatusDisplay from './components/StatusDisplay';

const Nav = styled('nav')(({ theme }) => ({
  borderTop: `4px solid ${theme.palette.blue[3]}`,
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '81px',
  zIndex: '2',
}));

const Navbar = () => {
  const popoverRef = useRef();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const popoverHandler = () => popoverRef.current.closePopover();
  return (
    <Box>
      <Nav color="default" sx={{ display: { xs: 'none', md: 'block' } }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" marginTop="14px">
            <NavbarBrand sx={{ mr: '1em' }} />
            <Box sx={{ mr: 'auto', fontWeight: '400' }}>
              <StatusDisplay />
            </Box>
            {auth.isLogin && (
              <NavbarItem
                name="profileNav"
                ref={popoverRef}
                Popover={<ProfileDashboard close={popoverHandler} />}
              >
                Hello, {user.username}
              </NavbarItem>
            )}
          </Stack>
        </Container>
      </Nav>
      <MobileView />
    </Box>
  );
};

export default Navbar;

import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

function createGradient2(color1, color2, color3, color4) {
  return `linear-gradient(to bottom right, ${color1}, ${color2}, ${color3}, ${color4})`;
}

// SETUP COLORS
const GREEN = {
  1: '#237C59',
  2: '#288E66',
  3: '#2DA073',
  100: '#004D3F',
  200: '#146153',
  300: '#287567',
  400: '#3C897B',
};
const BLUE = {
  0: '#0076EB',
  1: '#0042EB',
  2: '#1A5AFF',
  3: '#4C7FFF',
  4: '#80A4FF',
  100: '#0042EB',
  200: '#0076EB',
  300: '#009DEB',
  400: '#80DFFF',
  special: '#364F91',
};
const RED = {
  100: '#80DFFF',
  200: '#009DEB',
  300: '#0076EB',
  400: '#0042EB',
};
const YELLOW = {
  1: '#CC7400',
  2: '#DD8510',
  3: '#EE9520',
  4: '#FFA630',
};
const PINK = {
  100: '#FEA0F6',
  200: '#FFB4FF',
  300: '#FFC8FF',
  400: '#FFDCFF',
};
const ORANGE = {
  1: '#C24D1B',
  2: '#D35A26',
  3: '#E36730',
  4: '#F4743B',
  100: '#FF6F02',
  200: '#FF8316',
  300: '#FF972A',
  400: '#FFAB3E',
};

// MAIN COLORS
const PRIMARY = {
  lighter: '#EBF7F2',
  light: '#DCf7F2',
  main: BLUE[200],
  dark: '#004D3F',
  darker: '#004D3F',
};
const SECONDARY = {
  lighter: '#7FB4FF',
  light: '#7FB4FF',
  main: '#c9eeff',
  dark: '#4378ff',
  darker: '#4378ff',
};

// SETUP SEVERITY
const INFO = {
  light: '#E7FFFF',
  main: '#E7FFFF',
  dark: BLUE.dark,
};
const SUCCESS = {
  lighter: '#DCF7F2',
  light: '#DCF7F2',
  main: '#287567',
  dark: '#146153',
  darker: '#146153',
};
const WARNING = {
  lighter: '#FFF6EA',
  light: '#FFF6EA',
  main: '#FFF6EA',
  dark: '#FFAB3E',
  darker: '#FFAB3E',
};
const ERROR = {
  lighter: 'rgba(239, 118, 122, 0.1)',
  light: 'rgba(239, 118, 122, 0.1)',
  main: '#EF5D8E',
  dark: '#EF767A',
  darker: '#EF767A',
};

const GREY = {
  0: '#FAFAFA',
  1: '#F2F2F2',
  2: '#E0E0E0',
  3: '#BDBDBD',
  4: '#828282',
  5: '#505050',
  6: '#4F4F4F',
  7: '##FFFFFF',
  8: '#707070',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  special: createGradient2(BLUE[4], BLUE[3], BLUE[2], BLUE[1]),
  specialHovered: createGradient2(BLUE[400], BLUE[300], BLUE[200], BLUE[100]),
};

const COMMON = {
  common: { black: '#000000', white: '#ffffff' },
  primary: { ...PRIMARY, contrastText: '#ffffff' },
  secondary: { ...SECONDARY, contrastText: '#ffffff' },
  info: { ...INFO, contrastText: '#ffffff' },
  success: { ...SUCCESS, contrastText: GREY[100] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#ffffff' },
  grey: GREY,
  green: GREEN,
  orange: ORANGE,
  blue: BLUE,
  red: RED,
  yellow: YELLOW,
  pink: PINK,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hoverPrimary: 'rgba(50, 178, 128, 0.05)',
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  ...COMMON,
};

export default palette;

import { CheckCircle } from '@mui/icons-material';
import { Chip, CircularProgress, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'src/supports/api';

const StatusDisplay = () => {
  const [isOnline, setIsOnline] = useState(false);

  const callServer = () => {
    axios
      .get('/')
      .then(() => {
        setIsOnline(true);
      })
      .catch((err) => {
        setIsOnline(false);
        console.log(err);
      });
  };

  useEffect(() => {
    callServer();
    const checkServer = setInterval(() => {
      callServer();
    }, 60000); // every 60s
    return () => clearInterval(checkServer);
  }, []);

  return (
    <Grid container alignItems="center">
      {isOnline ? (
        <>
          <CheckCircle color="primary" />
          <Chip label="Online" color="primary" size="small" />
        </>
      ) : (
        <>
          <CircularProgress
            size={20}
            color="error"
            thickness={7}
            sx={{ mr: '4px' }}
          />
          <Chip label="Offline" color="error" size="small" />
        </>
      )}
      <Grid sx={{ ml: '1em' }}>
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
          <Grid item>THIS IS A MOCK SITE</Grid>
        )}
        <Grid item>Connected to {process.env.REACT_APP_BASE_URL}</Grid>
      </Grid>
    </Grid>
  );
};

export default StatusDisplay;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Collapse, Box, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout, logoutSuccess } from 'src/redux/modules/auth';
import NavbarBrand from './components/NavbarBrand';
import MobileButton from './components/MobileButton';

import icProfile from './components/Images/ic_profile.svg';

const Nav = styled('nav')(({ theme }) => ({
  borderTop: `3px solid ${theme.palette.blue[3]}`,
  backgroundColor: theme.palette.common.white,
  position: 'fixed',
  width: '100%',
  zIndex: '2',
}));

const Menu = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[2]}`,
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  position: 'relative',
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.grey[6],
  width: '100%',
}));

const ProfileNavbar = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[2]}`,
  borderTop: `1px solid ${theme.palette.grey[2]}`,

  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.grey[1],
}));

const ProfileUserWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ProfileNameWrapper = styled(Typography)(({ theme }) => ({
  marginLeft: '1rem',
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  letterSpacing: 'normal',
  color: theme.palette.grey[6],
}));

const ProfileEmailWrapper = styled(Typography)(({ theme }) => ({
  marginLeft: '1rem',
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  letterSpacing: 'normal',
  color: theme.palette.grey[4],
}));

const NavbarMobile = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setDropdownActive(!dropdownActive);
  };

  const dispacth = useDispatch();
  const userState = useSelector((state) => state.auth.user);

  const Profile = () => {
    return (
      <ProfileNavbar onClick={handleMobileMenu}>
        <Stack direction="row">
          <img
            src={`${process.env.REACT_APP_BASE_URL}/files/profile${userState.profilePicture}`}
            alt={userState.firstName}
            width="40px"
            height="40px"
            onError={(e) => {
              e.target.src = icProfile;
            }}
          />
          <ProfileUserWrapper>
            <Stack direction="column">
              <ProfileNameWrapper>
                {userState.firstName} {userState.lastName}
              </ProfileNameWrapper>
              <ProfileEmailWrapper>{userState.email}</ProfileEmailWrapper>
            </Stack>
          </ProfileUserWrapper>
        </Stack>
      </ProfileNavbar>
    );
  };

  const onLogout = () => {
    dispacth(logout());
    setMobileMenuOpen(false);
    setDropdownActive(false);
    dispacth(logoutSuccess());
  };

  return (
    <Nav color="default" sx={{ display: { xs: 'block', md: 'none' } }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ marginTop: '3px', marginBottom: '3px' }}
      >
        <NavbarBrand sx={{ mr: 'auto', ml: '10px' }} />
        <MobileButton isOpen={mobileMenuOpen} onClick={handleMobileMenu} />
      </Stack>
      <Box>
        <Collapse in={dropdownActive} unmountOnExit>
          <Link to="/dashboard/user-info">
            <Profile />
          </Link>
          <Link to="/">
            <Menu onClick={handleMobileMenu}>Home</Menu>
          </Link>
          <Link to="/talents">
            <Menu onClick={handleMobileMenu}>Find Talent</Menu>
          </Link>
          <Menu onClick={onLogout}>Logout</Menu>
        </Collapse>
      </Box>
    </Nav>
  );
};

export default NavbarMobile;

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

const MobileButton = ({ isOpen, onClick }) => {
  return (
    <IconButton
      size="small"
      sx={{
        display: {
          xs: 'block',
          md: 'none',
        },
      }}
      onClick={onClick}
    >
      {isOpen ? (
        <CloseIcon fontSize="inherit" />
      ) : (
        <MenuIcon fontSize="inherit" />
      )}
    </IconButton>
  );
};

MobileButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MobileButton;

// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import encryption from 'src/supports/encryption';
import axios from 'src/supports/api';

const initialUser = {
  id: null,
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  company: '',
  profilePicture: '',
  isAdmin: false,
};

const initialState = {
  user: initialUser,
  token: null,
  loading: false,
  message: '',
  error: false,
  tokenChecked: false,
  isSignInModalOpen: false,
  isLogin: false,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.message = '';
      state.error = false;
    },
    setUser: (state, action) => {
      const {
        id,
        username,
        email,
        firstName,
        lastName,
        phone,
        company,
        profilePicture,
        isAdmin,
      } = action.payload;

      state.user = {
        id,
        username,
        email,
        firstName,
        lastName,
        phone,
        company,
        profilePicture,
        isAdmin,
      };
    },
    logout: (state) => {
      // removes all localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('loggedUsername');

      state.user = initialUser;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload?.message || 'Login Success';
      state.isSignInModalOpen = false;
      state.isLogin = true;
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.error = true;
      state.message = action.payload.message || 'Login Failed';
      state.user = initialState;
    },
    logoutStart: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.message = 'Logout Success';
      state.isSignInModalOpen = true;
      state.isLogin = false;
    },
    logoutFail: (state) => {
      state.loading = false;
      state.error = true;
      state.message = 'Logout Failed';
    },
    keepLoginStart: (state) => {
      state.loading = true;
    },
    keepLoginSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload?.message || 'Keep Login Success';
      state.tokenChecked = true;
      state.isSignInModalOpen = false;
      state.isLogin = true;
    },
    keepLoginFailed: (state, action) => {
      state.loading = false;
      state.message = action.payload.message || 'Keep Login Failed';
      state.tokenChecked = true;
      state.isSignInModalOpen = true;
    },
  },
});

export default reducer;

export const {
  loginStart,
  setUser,
  loginFail,
  loginSuccess,
  logoutStart,
  logout,
  logoutSuccess,
  logoutFail,
  keepLoginStart,
  keepLoginSuccess,
  keepLoginFailed,
  setCompletion,
} = actions;

export const signIn = (data) => {
  return async (dispatch) => {
    dispatch(loginStart());
    const { username, password, remember } = data;
    try {
      const encryptedPassword = encryption.encrypt(password); // encrypted password
      const res = await axios.post(`/auth/login`, {
        username,
        encryptedPassword,
        remember,
      });
      const { token } = res.data.result;

      localStorage.setItem('token', token || null);
      localStorage.setItem('loggedUsername', username);
      dispatch(setUser(res.data.result));
      dispatch(loginSuccess());
    } catch (err) {
      const message = err.response?.data?.error || err.message;
      dispatch(loginFail({ message }));
    }
  };
};

export const keepLogin = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');

    dispatch(keepLoginStart());
    if (token) {
      try {
        const res = await axios.post(`/auth/keep-login`);
        dispatch(setUser(res.data.result));
        dispatch(keepLoginSuccess());
      } catch (err) {
        const message = err.response?.data.message || err.message;
        dispatch(keepLoginFailed({ message }));
      }
    } else {
      dispatch(keepLoginFailed({ message: 'No Token' }));
    }
  };
};

export const adminLogin = (data) => {
  return async (dispatch) => {
    dispatch(loginStart());
    const { usernameOrEmail, password, remember } = data;

    try {
      const encryptedPassword = encryption.encrypt(password);
      const res = await axios.post(`/auth/admin-login`, {
        usernameOrEmail,
        encryptedPassword,
        remember,
      });
      const { token } = res.data.result;

      localStorage.setItem('token', token || null);
      localStorage.setItem('loggedUsernameOrEmail', usernameOrEmail);
      dispatch(setUser(res.data.result));
      dispatch(loginSuccess());
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      dispatch(loginFail({ message }));
    }
  };
};

import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout, logoutSuccess } from 'src/redux/modules/auth';
import PropTypes from 'prop-types';

import icProfile from './Images/ic_profile.svg';
import icHome from './Images/ic_home.svg';
import icLogout from './Images/ic_logout.svg';

const DashboardContainer = styled(Box)(() => ({
  paddingTop: '20px',
  minWidth: '333px',
}));

const ProfileWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '32px',
  paddingRight: '32px',
  paddingBottom: '1rem',
}));

const ProfileUserWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '20px',
}));

const ProfileNameWrapper = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  letterSpacing: 'normal',
  color: theme.palette.grey[6],
}));

const ProfileEmailWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '0.25rem',
}));

const BorderBottom = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[2]}`,
}));

const IconContainerWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: '32px',
  paddingRight: '32px',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '14px',
  fontFamily: 'CircularStd',
  transition: '0.15s',
  color: theme.palette.grey[8],
  borderRadius: '6px',
  paddingLeft: '12px',
}));

const IconImage = styled('img')(() => ({
  width: '24px',
  height: '24px',
  marginRight: '1rem',
  filter:
    'invert(40%) sepia(38%) saturate(0%) hue-rotate(133deg) brightness(120%) contrast(102%)',
}));

const SpanText = styled(Box)(() => ({
  paddingTop: '0.25rem',
}));

const ProfileDashboard = ({ close }) => {
  const dispacth = useDispatch();
  const userState = useSelector((state) => state.auth.user);

  const onLogout = () => {
    dispacth(logout());
    dispacth(logoutSuccess());
    close();
  };

  return (
    <DashboardContainer>
      <ProfileWrapper>
        <img
          src={`${process.env.REACT_APP_BASE_URL}/files/profile${userState.profilePicture}`}
          alt={userState.firstName}
          width="40px"
          height="40px"
          borderRadius="6px"
          marginRight="1rem"
          onError={(e) => {
            e.target.src = icProfile;
          }}
        />
        <ProfileUserWrapper>
          <ProfileNameWrapper>
            {userState.firstName} {userState.lastName}
          </ProfileNameWrapper>
          <ProfileEmailWrapper>{userState.email}</ProfileEmailWrapper>
        </ProfileUserWrapper>
      </ProfileWrapper>
      <BorderBottom />
      <Link to="/" style={{ textDecoration: 'none' }} onClick={close}>
        <IconContainerWrapper>
          <IconWrapper>
            <IconImage src={icHome} alt="ic_home" />
            <SpanText component="span">Home</SpanText>
          </IconWrapper>
        </IconContainerWrapper>
      </Link>
      <BorderBottom />
      <Box>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <IconContainerWrapper>
            <IconWrapper onClick={onLogout}>
              <IconImage src={icLogout} alt="ic_logout" />
              <SpanText component="span">Logout</SpanText>
            </IconWrapper>
          </IconContainerWrapper>
        </Link>
      </Box>
    </DashboardContainer>
  );
};

ProfileDashboard.propTypes = {
  close: PropTypes.func,
};

export default ProfileDashboard;

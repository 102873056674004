import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Typography, Menu } from '@mui/material';
import { useSelector } from 'react-redux';

import icProfile from './Images/ic_profile.svg';
/* eslint-disable react/jsx-props-no-spreading */

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 'bold',
  marginRight: '4px',
}));

const UserProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StackContentWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  color: theme.palette.grey[6],
  transition: 'color 0.3s',
  '&:hover': {
    color: `${theme.palette.primary.main}`,
  },
  '&:after': {
    content: '""',
    display: 'block',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    width: 0,
    position: 'absolute',
    left: 0,
    top: '24px',
    WebkitTransition: '0.3s ease',
    transition: '0.3s ease',
  },
  '&:hover:after': {
    width: '95%',
  },
}));

const NavbarItem = ({ name, children, Popover, ...props }, ref) => {
  const theme = useTheme();

  const userState = useSelector((state) => state.auth.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    closePopover() {
      handleClose();
    },
  }));

  return (
    <Box
      sx={{ mr: '24px', display: { xs: 'none', md: 'block' }, ...props?.sx }}
      {...props}
    >
      <Stack direction="row">
        <StackContentWrapper
          direction="row"
          alignItems="center"
          onClick={name === 'profileNav' ? handleClick : null}
        >
          {name === 'profileNav' ? (
            <Title sx={{ color: theme.palette.blue.special }}>{children}</Title>
          ) : (
            <Title>{children}</Title>
          )}
        </StackContentWrapper>
        <UserProfileContainer>
          {name === 'profileNav' ? (
            <Box sx={{ marginLeft: '10px', marginTop: '5px' }}>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/files/profile${userState.profilePicture}`}
                alt={userState.firstName}
                width="24px"
                height="24px"
                onError={(e) => {
                  e.target.src = icProfile;
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </UserProfileContainer>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          width: '100%',
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: '56px',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '50%',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {Popover}
      </Menu>
    </Box>
  );
};

NavbarItem.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  setOverlay: PropTypes.func,
  Popover: PropTypes.node,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default forwardRef(NavbarItem);

import crypto from 'crypto';
import { appKey, algorithm, iv } from 'src/supports/config';

const encryption = {
  encrypt: (plain) => {
    const cipher = crypto.createCipheriv(algorithm, appKey, iv);
    let crypted = cipher.update(plain, 'utf-8', 'hex');
    crypted += cipher.final('hex');

    return crypted;
  },
};

export default encryption;

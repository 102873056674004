import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'src/App.css';
import { keepLogin } from 'src/redux/modules/auth';
import Navbar from './components/Navbar';

// no longer used
// import SignInModal from 'src/components/Modals/SignInModal';
// const HomePage = lazy(() => import('./pages/home'));
// const DashboardPage = lazy(() => import('./pages/dashboard'));
// const UserInfoPage = lazy(() =>
//   import('./pages/dashboard/components/UserInfoPage'),
// );
// const SavedTalentsPage = lazy(() =>
//   import('./pages/dashboard/components/SavedTalentsPage'),
// );
// const TalentDetailPage = lazy(() => import('./pages/talent-detail'));
// const TalentsPage = lazy(() => import('./pages/talents'));
// const AlumniStoriesPage = lazy(() => import('./pages/alumni-stories'));

const AdminLoginPage = lazy(() => import('./pages/admin-login'));
const AdminDashboard = lazy(() => import('./pages/admin-dashboard'));
const NotFoundPage = lazy(() => import('./pages/not-found'));
const TalentListPage = lazy(() =>
  import('./pages/admin-dashboard/pages/TalentList'),
);
const UserListPage = lazy(() =>
  import('./pages/admin-dashboard/pages/user-list'),
);
const InputFormTalentPage = lazy(() =>
  import('./pages/admin-dashboard/pages/InputFormTalentPage'),
);
const AdminDashboardPage = lazy(() =>
  import('./pages/admin-dashboard/pages/Dashboard'),
);

const TotalTalentViewedPage = lazy(() =>
  import(
    './pages/admin-dashboard/pages/Dashboard/TotalPages/TotalTalentViewedPage'
  ),
);
const TotalTalentCvDownloadedPage = lazy(() =>
  import(
    './pages/admin-dashboard/pages/Dashboard/TotalPages/TotalTalentCvDownloadedPage'
  ),
);
const TotalTalentWhatsappPage = lazy(() =>
  import(
    './pages/admin-dashboard/pages/Dashboard/TotalPages/TotalTalentWhatsappPage'
  ),
);
const TotalTalentEmailPage = lazy(() =>
  import(
    './pages/admin-dashboard/pages/Dashboard/TotalPages/TotalTalentEmailPage'
  ),
);
const TotalTalentSavedPage = lazy(() =>
  import(
    './pages/admin-dashboard/pages/Dashboard/TotalPages/TotalTalentSavedPage'
  ),
);
const TransactionPage = lazy(() => import('./pages/transaction'));
const TransactionDetailPage = lazy(() => import('./pages/transaction-detail'));

const App = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(keepLogin());
  }, [dispatch]);

  return (
    <Suspense fallback={<></>}>
      <Navbar />
      <Routes>
        {authState.isLogin ? (
          <Route path="/" element={<Navigate to="/admin" />} />
        ) : (
          <Route path="/" element={<AdminLoginPage />} />
        )}

        {(!authState.tokenChecked || authState.user.isAdmin) && (
          <>
            <Route path="admin" element={<Navigate to="/admin/dashboard" />} />
            <Route path="admin" element={<AdminDashboard />}>
              <Route exact path="dashboard" element={<AdminDashboardPage />} />
              <Route path="talent-list" element={<TalentListPage />} />
              <Route
                exact
                path="talents/create"
                element={<InputFormTalentPage type="post" />}
              />
              <Route
                path="talents/:id/edit"
                element={<InputFormTalentPage type="put" />}
              />
              <Route path="user-list" element={<UserListPage />} />
              <Route
                path="dashboard/talent-viewed"
                element={<TotalTalentViewedPage />}
              />
              <Route
                path="dashboard/talent-cv-download"
                element={<TotalTalentCvDownloadedPage />}
              />
              <Route
                path="dashboard/talent-whatsapp"
                element={<TotalTalentWhatsappPage />}
              />
              <Route
                path="dashboard/talent-email"
                element={<TotalTalentEmailPage />}
              />
              <Route
                path="dashboard/talent-saved"
                element={<TotalTalentSavedPage />}
              />
              <Route path="transaction" exact element={<TransactionPage />} />
              <Route
                path="transaction/:id"
                exact
                element={<TransactionDetailPage />}
              />
            </Route>
          </>
        )}
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="not-found" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
